import basket from './icons/basket.svg'
import logoutIcon from './icons/exit.svg'
import defaultAdminIcon from './icons/default-admin-icon.png'
import productsManagementIcon from './icons/products-management-icon.svg'
import supportIcon from './icons/support.svg'
import statisticsIcon from './icons/statistics.svg'
import shopsIcon from './icons/shops.svg'
import settingsIcon from './icons/settings.svg'
import rolesIcon from './icons/roles.svg'
import reviewsIcon from './icons/reviews.svg'
import deliveryIcon from './icons/delivery.svg'
import clientManagementIcon from './icons/client-management-icon.svg'
import categoriesIcon from './icons/categories-icon.svg'
import bonusSystem from './icons/bonus-system.svg'
import bannerIcon from './icons/banners.svg'
import showPasswordIcon from './icons/show-password-icon.svg'
import hidePasswordIcon from './icons/hidePassword.svg'
import plusIcon from './icons/plus-icon.svg'
import deleteIcon from './icons/delete.svg'
import descriptionIcon from './icons/description.svg'
import arrowRight from './icons/ArrowRight.svg'
import arrowLeft from './icons/arrowLeft.svg'
import commentIcon from './icons/comment-icon.svg'
import calendar from './icons/calendar.svg'
import arrowDownIcon from './icons/arrow-down.svg'
import cross from './icons/cross.svg'
import search from './icons/search.svg'
import minus from './icons/minus.svg'
import plus from './icons/plus-grey.svg'
import starYellow from './icons/starYellow.svg'
import starWhite from './icons/starWhite.svg'

export class Assets {

  static readonly BASKET = basket

	static readonly LOGOUT_ICON = logoutIcon

	static readonly DEFAULT_ADMIN_ICON = defaultAdminIcon

	static readonly PRODUCTS_MANAGEMENT_ICON = productsManagementIcon

	static readonly SHOW_PASSWORD_ICON = showPasswordIcon

	static readonly HIDE_PASSWORD_ICON = hidePasswordIcon

	static readonly SUPPORT_ICON = supportIcon

	static readonly STATISTICS_ICON = statisticsIcon

	static readonly SHOPS_ICON = shopsIcon

	static readonly SETTINGS_ICON = settingsIcon

	static readonly ROLES_ICON = rolesIcon

	static readonly REVIEWS_ICON = reviewsIcon

	static readonly DELIVERY_ICON = deliveryIcon

	static readonly CLIENT_MANAGEMENT_ICON = clientManagementIcon

	static readonly CATEGORIES_ICON = categoriesIcon

	static readonly BONUS_SYSTEM_ICON = bonusSystem

	static readonly BANNER_ICON = bannerIcon

	static readonly PLUS_ICON = plusIcon

	static readonly DELETE_ICON = deleteIcon

	static readonly DESCRIPTION_ICON = descriptionIcon

	static readonly ARROW_RIGHT = arrowRight

	static readonly ARROW_LEFT = arrowLeft

	static readonly COMMENT_ICON = commentIcon

	static readonly CALENDAR = calendar

	static readonly ARROW_DOWN_ICON = arrowDownIcon

	static readonly CROSS = cross

	static readonly SEARCH = search

	static readonly MINUS = minus

	static readonly PLUS = plus

	static readonly STAR_YELLOW = starYellow

	static readonly STAR_WHITE = starWhite
}
