export enum ELocales {
  en = "en",
  de = "de",
  uk = "ua",
  ru = "ru",
  default = "ua",
}

export enum EColors {
  green = "#31A52F",
  gray = "#818181",
  red = "#DC3333",
  black = "#222222",
  blue = "#0000ff",
  blue2 = "#000080",
  yellow = "#ffff00",
  default = "#F18018",
}
